<style scoped lang="less">
  .config-select-page {
    display: flex;
    flex-direction: column;
    padding: 20px;
    box-sizing: border-box;
  }
  .header {
    background-color: #FFF;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .body {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #FFF;
    height: 0;
    flex: 1;
    position: relative;
  }
  .list {
    margin-bottom: 15px;
    max-height: 200px;
    overflow: auto;
    padding-right: 10px;
    display: block;
  }
  .item {
    & + .item {
      margin-top: 15px;
    }
    .icon-close {
      opacity: 0;
      cursor: pointer;
      transition: all .3s;
    }
    &:hover {
      .icon-close {
        opacity: 1;
      }
    }
    & > * + * {
      margin-left: 10px;
    }
  }
  .order-set {
    display: flex;
    flex-direction: column;
    i {
      cursor: pointer;
    }
  }
</style>

<template>
  <div class="config-select-page">
    <div class="header">
      <fm-title title-text="可选项配置"></fm-title>
    </div>
    <div class="body">
      <fm-table-new
        simple-filter
        :columns="columns"
        auto-height
        v-loadingx="loading.load"
        border="row"
        ref="table"
        :data-list="dataList"
        :toolbox="toolbox"
        export-file-name="可选项配置"
        :stripe="false"
        size="small"
        emptyPlaceholder="-">
          <table-actions slot="actions" @table-action="tableAction" slot-scope="{ row }" :data="tableActions" :row="row"></table-actions>
          <table-empty slot="empty" />
      </fm-table-new>
    </div>
    <fm-modal :mask-closable="false" v-model="modal" width="500px" theme="mh-blackt">
      <div slot="header" style="display: flex;justify-content: space-between;align-items: center;padding-right: 35px;">
        可选项配置明细
        <fm-input-new placeholder="搜索" size="small" v-model="searchKey" clearable>
          <i class="iconfont icon-search" slot="suffix" style="padding-right: 5px"></i>
        </fm-input-new>
      </div>
      <table class="list" ref="list">
        <tr class="item">
          <td>名称</td>
          <td>内容</td>
          <td>顺序</td>
          <td>&nbsp;</td>
        </tr>
        <tr v-show="!searchKey || item.label.indexOf(searchKey) > -1" v-for="(item, order) in chooseDetails" :key="order + '-' + item.orderNum" class="item">
          <td>
            <fm-input-new v-model="item.label" placeholder="名称" />
          </td>
          <td>
            <fm-input-new v-model="item.value" placeholder="内容" />
          </td>
          <td>
            <fm-input-number :min="0" :max="chooseDetails.length - 1" v-model="item.orderNum" placeholder="排序" />
          </td>
          <td>
            <i class="iconfont icon-close" @click="delItem(order)"></i>
          </td>
        </tr>
      </table>
      <fm-btn v-loadingx="loading.save" block @click="addItem">新增</fm-btn>
      <fm-btn v-loadingx="loading.save" style="margin-top: 15px;" block type="primary" @click="save">保存</fm-btn>
    </fm-modal>
  </div>
</template>

<script>
import TableActions from '@/components/base/TableActions'
import TableEmpty from '@/components/base/TableEmpty'
import { configsRequest } from '@/api'

import Config from '@/config'

export default {
  components: { TableActions, TableEmpty },
  data () {
    return {
      loading: {
        load: false,
        save: false
      },
      dataList: [],
      modal: false,
      chooseData: null,
      chooseDetails: [],
      searchKey: null
    }
  },
  computed: {
    tableActions () {
      return [
        { label: '明细', key: 'details' },
        { label: '备注', key: 'remark' }
      ]
    },
    toolbox () {
      return ['table-export', 'table-config'].filter(v => this.$authFunsProxy[v]).map(v => v.replace('table-', ''))
    },
    baseColumns () {
      return [
        { title: '名称', field: 'name' },
        { title: '键名', field: 'configKey' },
        { title: '数量', field: 'length' },
        { title: '备注', field: 'remark' },
        { title: '操作', slot: 'actions', fixed: 'right', search: false, export: false, configurable: false }
      ]
    },
    columns () {
      return this.$getTableConfig('main', this.baseColumns)
    }
  },
  methods: {
    async save () {
      let data = this.chooseDetails.sort((a, b) => {
        return Number(a.orderNum) - Number(b.orderNum)
      }).map((v, i) => {
        return Object.assign({...v}, {orderNum: i})
      })

      this.loading.save = true
      await configsRequest.add({
        type: this.chooseData.type,
        configKey: this.chooseData.configKey,
        value: JSON.stringify(data),
        remark: this.chooseData.remark
      })
      this.loadData()
      this.loading.save = false
      this.modal = false
    },
    delItem (order) {
      this.chooseDetails.splice(order, 1)
    },
    addItem () {
      this.chooseDetails.push({
        label: '',
        value: '',
        orderNum: this.chooseDetails.length
      })
      this.$nextTick(() => {
        this.$refs.list.scrollTo(0, this.$refs.list.scrollHeight)
      })
    },
    tableAction ({action, data}) {
      this.chooseData = data
      if (action === 'details') {
        this.modal = true
        this.chooseDetails = data.value ? data.value.sort((a, b) => Number(a.orderNum) - Number(b.orderNum)) : []
      } else if (action === 'remark') {
        this.setRemark(data)
      }
    },
    async setRemark (data) {
      let res = await this.$dialog.prompt({
        title: '请输入[' + (this.chooseData.name || '未命名选项') + ']的备注信息',
        promptValue: data.remark,
        inputType: 'textarea'
      })
      if (res !== false) {
        await configsRequest.add({
          id: this.chooseData.id,
          type: this.chooseData.type,
          configKey: this.chooseData.configKey,
          value: JSON.stringify(this.chooseData.value),
          remark: res
        })
        this.loadData()
      }
    },
    async loadData() {
      this.loading.load = true
      let res = await configsRequest.get({type: 'selectData'})
      let dataList = []
      Config.selectData.forEach(v => {
        let dataItem = JSON.parse(JSON.stringify(v))
        dataItem.type = 'selectData'
        dataItem.name = dataItem.label
        dataItem.value = dataItem.defaultValue.map(v1 => {
          return {value: v1.key, label: v1.label}
        })
        dataItem.configKey = dataItem.key
        let value = res.find(v1 => v1.configKey === v.key)
        if (value) {
          dataItem.value = JSON.parse(value.value)
          dataItem.remark = value.remark
        }
        dataItem.length = dataItem.value ? dataItem.value.length : 0
        dataList.push(dataItem)
      })
      this.dataList = dataList
      this.loading.load = false
    }
  },
  mounted () {
    this.loadData()
  }
}
</script>
